<form
  id="register-form"
  class="form"
  [formGroup]="form"
  (ngSubmit)="register()"
>
  <legend>
    <h1>{{ "register.title" | translate }}</h1>
  </legend>

  @if (error(); as error) {
    <p class="message error">
      {{ error }}
    </p>
  }

  @if (message(); as message) {
    <p class="message success">
      {{ message }}
    </p>
  }

  <fieldset class="controls" [disabled]="loading()">
    <div class="field">
      <label for="first-name-input" class="label">
        {{ "register.form.first-name.label" | translate }}
      </label>
      <input
        id="first-name-input"
        type="text"
        class="input"
        placeholder="{{ 'register.form.first-name.placeholder' | translate }}"
        formControlName="first_name"
      />

      <app-form-error
        [control]="form.get('first_name')"
        [messages]="{
          required: 'register.form.first-name.errors.required' | translate,
        }"
      ></app-form-error>
    </div>

    <div class="field">
      <label for="last-name-input" class="label">
        {{ "register.form.last-name.label" | translate }}
      </label>
      <input
        id="last-name-input"
        type="text"
        class="input"
        placeholder="{{ 'register.form.last-name.placeholder' | translate }}"
        formControlName="last_name"
      />

      <app-form-error
        [control]="form.get('last_name')"
        [messages]="{
          required: 'register.form.last-name.errors.required' | translate,
        }"
      ></app-form-error>
    </div>

    <div class="field">
      <label for="email-input" class="label">
        {{ "register.form.email.label" | translate }}
      </label>
      <input
        id="email-input"
        type="email"
        class="input"
        placeholder="{{ 'register.form.email.placeholder' | translate }}"
        formControlName="email"
      />

      <app-form-error
        [control]="form.get('email')"
        [messages]="{
          required: 'register.form.email.errors.required' | translate,
          email: 'register.form.email.errors.email' | translate,
        }"
      ></app-form-error>
    </div>

    <div class="field">
      <label for="username-input" class="label">
        {{ "register.form.username.label" | translate }}
      </label>
      <input
        id="username-input"
        type="text"
        class="input"
        placeholder="{{ 'register.form.username.placeholder' | translate }}"
        formControlName="username"
      />

      <app-form-error
        [control]="form.get('username')"
        [messages]="{
          required: 'register.form.username.errors.required' | translate,
        }"
      ></app-form-error>
    </div>

    <div class="field">
      <label for="birthday-input" class="label">
        {{ "register.form.birthday.label" | translate }}
      </label>
      <input
        id="birthday-input"
        type="date"
        class="input"
        formControlName="date_of_birth"
      />

      <app-form-error
        [control]="form.get('date_of_birth')"
        [messages]="{
          required: 'register.form.birthday.errors.required' | translate,
          before: 'register.form.birthday.errors.age' | translate,
        }"
      ></app-form-error>
    </div>

    <div class="field">
      <label for="country-input" class="label">
        {{ "register.form.country.label" | translate }}
      </label>
      <select
        id="country-input"
        class="input"
        formControlName="address_country_id"
      >
        <option value="" selected disabled>
          {{ "register.form.country.placeholder" | translate }}
        </option>
        @for (country of countries(); track $index) {
          <option value="{{ country.id }}">{{ country.name }}</option>
        }
      </select>

      <app-form-error
        [control]="form.get('address_country_id')"
        [messages]="{
          required: 'register.form.country.errors.required' | translate,
        }"
      ></app-form-error>
    </div>

    <div class="field">
      <label for="password-input" class="label">
        {{ "register.form.password.label" | translate }}
      </label>
      <input
        id="password-input"
        type="password"
        class="input"
        placeholder="{{ 'register.form.password.placeholder' | translate }}"
        formControlName="password"
      />

      <app-form-error
        [control]="form.get('password')"
        [messages]="{
          required: 'register.form.password.errors.required' | translate,
          uppercase: 'register.form.password.errors.uppercase' | translate,
          lowercase: 'register.form.password.errors.lowercase' | translate,
          digit: 'register.form.password.errors.digit' | translate,
          special: 'register.form.password.errors.special' | translate,
          minlength: 'register.form.password.errors.minlength' | translate,
        }"
      ></app-form-error>
    </div>

    <div class="field">
      <label for="password-confirmation-input" class="label">
        {{ "register.form.confirm-password.label" | translate }}
      </label>
      <input
        id="password-confirmation-input"
        type="password"
        class="input"
        placeholder="{{
          'register.form.confirm-password.placeholder' | translate
        }}"
        formControlName="password_confirmation"
      />

      <app-form-error
        [control]="form.get('password_confirmation')"
        [messages]="{
          required:
            'register.form.confirm-password.errors.required' | translate,
          mismatch:
            'register.form.confirm-password.errors.mismatch' | translate,
        }"
      ></app-form-error>
    </div>

    <div class="field">
      <label for="terms-input">
        <input
          id="terms-input"
          type="checkbox"
          class="checkbox"
          formControlName="terms"
        />&nbsp; {{ "register.form.terms.prefix" | translate }}&nbsp;
        <a
          class="link-text"
          href="https://fxify.com/terms-and-conditions/"
          target="_blank"
          >{{ "register.form.terms.label" | translate }}</a
        >
      </label>
    </div>

    <div class="field">
      <label for="correct-details-input">
        <input
          id="correct-details-input"
          type="checkbox"
          class="checkbox"
          formControlName="correct_details"
        />&nbsp;
        {{ "register.form.correct-details.label" | translate }}
      </label>
    </div>

    <div class="field">
      <label for="only-one-registration-input">
        <input
          id="only-one-registration-input"
          type="checkbox"
          class="checkbox"
          formControlName="only_one_registration"
        />&nbsp;
        {{ "register.form.one-registration.label" | translate }}
      </label>
    </div>

    <input
      type="submit"
      app-filled-button
      theme="primary"
      value="{{
        (loading()
          ? 'register.form.actions.submit.loading'
          : 'register.form.actions.submit.idle'
        ) | translate
      }}"
      [disabled]="form.invalid || loading()"
    />
  </fieldset>

  <p>
    {{ "register.links.login.prefix" | translate }}&nbsp;
    <a class="link-text" [routerLink]="['/login']">
      {{ "register.links.login.title" | translate }}
    </a>
  </p>
</form>
