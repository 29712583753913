<div id="toasts">
  @for(item of toasts() | keyvalue; track item.key; let i = $index) {
  <div @toast class="toast" aria-live="polite" [style.--index]="i">
    <div class="toast-message">
      {{ item.value.message }}
    </div>
    <div class="toast-action">
      <button (click)="item.value.clear()" class="close">
        <span class="fa fa-times"></span>
      </button>
    </div>
  </div>
  }
</div>