import { InjectionToken, makeEnvironmentProviders } from '@angular/core';

export interface Checkout {
  url: (id: string, key: string) => string;
}

export const CHECKOUT = new InjectionToken<Checkout>('CHECKOUT');

export const provideCheckout = (url: (id: string, key: string) => string) => {
  return makeEnvironmentProviders([
    {
      provide: CHECKOUT,
      useValue: { url },
    },
  ]);
};
