import { computed, inject, Injectable, signal } from '@angular/core';
import { catchError, exhaustMap, of, tap, throwError } from 'rxjs';

import { ApiService } from '@features/api';

import {
  User,
  Auth,
  Login,
  Register,
  Profile,
  Recover,
  Reset,
  ChangePassword,
} from '../models';
import { JwtService } from './jwt.service';

@Injectable()
export class AuthService {
  protected readonly api = inject(ApiService);
  protected readonly jwt = inject(JwtService);

  readonly #authenticated = signal(false);
  public readonly authenticated = this.#authenticated.asReadonly();

  readonly #user = signal<User | null>(null);
  public readonly user = this.#user.asReadonly();

  login(payload: Login) {
    return this.api.post<Auth>('/user/login', payload).pipe(
      tap(({ token }) => this.jwt.save(token)),
      exhaustMap(() => this.populate()),
    );
  }

  logout() {
    return this.api.delete<void>('/user/logout').pipe(tap(() => this.clear()));
  }

  register(payload: Register) {
    return this.api.post<{ message: string }>('/user/register', payload);
  }

  recover(payload: Recover) {
    return this.api.post<{ message: string }>(
      '/user/password/forgot-password',
      payload,
    );
  }

  reset(payload: Reset) {
    return this.api.post<{ message: string }>(
      '/user/password/reset-password',
      payload,
    );
  }

  populate() {
    return this.api.get<User>('/user/self').pipe(
      tap((user) => {
        this.#user.set(user);
        this.#authenticated.set(true);
      }),
      catchError((error) => {
        this.clear();
        return throwError(() => error);
      }),
    );
  }

  update(payload: Profile) {
    return this.api
      .post<User>('/user', payload)
      .pipe(tap((user) => this.#user.set(user)));
  }

  upload(file: File) {
    return this.api
      .upload<User>('/user/profile/image', file, 'profile_image')
      .pipe(tap((user) => this.#user.set(user)));
  }

  changePassword(payload: ChangePassword) {
    return this.api.post<{ message: string }>(
      '/user/password/change-password',
      { ...payload },
    );
  }

  protected clear() {
    this.jwt.destroy();
    this.#user.set(null);
    this.#authenticated.set(false);
  }
}
