import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, merge, of } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

import { LOCALES } from '../locales';

@Injectable({
  providedIn: 'root',
})
export class LocalisationService {
  #translate = inject(TranslateService);
  public readonly locales = inject(LOCALES);

  public readonly language = toSignal(
    merge(
      of(this.#translate.defaultLang),
      this.#translate.onLangChange.pipe(map((e) => e.lang)),
    ),
  );

  use(locale: string) {
    localStorage.setItem('language', locale);
    return this.#translate.use(locale);
  }

  instant(key: string, params?: Record<string, unknown>) {
    return this.#translate.instant(key, params);
  }

  translate(key: string, params?: Record<string, unknown>) {
    return this.#translate.get(key, params);
  }
}
