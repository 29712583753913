import { Component, computed, inject } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import { ToastService } from './toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('toast', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-100%)' }),
        animate('300ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('300ms', style({ opacity: 0, transform: 'translateX(-50%)' })),
      ]),
    ]),
  ],
})
export class ToastComponent {
  protected service = inject(ToastService);
  protected toasts = computed(() => this.service.toasts());
}
