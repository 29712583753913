import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';

export const languageInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const locale = localStorage.getItem('language');

  if (locale) {
    req = req.clone({
      setHeaders: {
        'Accept-Language': locale,
      },
    });
  }

  return next(req);
};
