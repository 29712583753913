import { Injectable, signal } from '@angular/core';
import { Toast } from './toast';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  #toasts = signal<Record<number, Toast>>({});
  toasts = this.#toasts.asReadonly();

  show(message: string, duration = 5000) {
    const id = Date.now();

    const clear = () => {
      window.clearTimeout(timeout);
      this.remove(id);
    };

    this.#toasts.update((toasts) => ({ ...toasts, [id]: { message, clear } }));

    const timeout = window.setTimeout(() => clear(), duration);
  }

  protected remove(id: number) {
    this.#toasts.update((toasts) => {
      const { [id]: _, ...rest } = toasts;
      return rest;
    });
  }
}
